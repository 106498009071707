import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import Img from "gatsby-image"

export const pageQuery = graphql`
  query MagnetQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Magnet = ({data}) => {
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark
  let imgLink, path, qsCta, qsNew, qsPath, qsTitle, urlNew //, qsDesc
  if (typeof window !== `undefined`) {
    const params = new URLSearchParams(document.location.search.substring(1))
    qsTitle = params.get("title") // title=Home%20Remodelers%20Marketing%20Toolkit
    // qsDesc = params.get("desc")   // desc=For%20Home%20Remodeler
    qsPath = params.get("path")   // path=$assets$pdf$Marketing_Toolkit_for_Home_Remodelers.pdf
    qsCta = (params.get("cta")) ? params.get("cta") : "Get Now"
    path = `download-confirmation?title=${qsTitle}&path=${qsPath}`
    qsNew = `title=${qsTitle}&path=${qsPath}`
    urlNew = `https://dornfeld.capital/download-confirmation?${qsNew}`
    imgLink = qsPath.replaceAll('$', '/').replace('/pdf', '/covers').replace('.pdf', '.png')
  }

  return  (
    <Layout className="magnet-page">
      <SEO 
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
      />
      <div className="wrapper">
        <img className="magnet-img" src={imgLink} alt="Magnet"></img>
        <h1 className="magnet-txt">{qsTitle}</h1>
        <p className="magnet-txt">Fill out the form below to download this exclusive offer for FREE.</p>
        <form className="magnet-form" action={path} name="magnet" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="magnet" />
          <input type="hidden" name="form-title" value={qsTitle} />
          <input type="hidden" name="form-path" value={qsPath} />
          <input type="hidden" name="form-qs" value={urlNew} />
          <p>
            <label>Name<input type="text" name="name" required /></label>   
          </p>
          <p>
            <label>Email<input type="email" name="email" required /></label>
          </p>
          <p className="text-align-right">
            <button className="button" type="submit">{qsCta}</button>
          </p>
        </form>
      </div>
    </Layout>
  )
}

export default Magnet